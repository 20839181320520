import { Divider, Grid, useTheme } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import TextFieldCustom from 'components/common/TextFieldCustom/TextFieldCustom';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import keys from 'lodash/keys';
import map from 'lodash/map';
import Measurer from 'models/Measurer';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

function transformObjectToArray(obj: any) {
  if (obj === undefined) {
    return [];
  }
  const _keys = keys(obj);
  return map(_keys, (key) => {
    return { key, value: obj[key] };
  });
}

const measurerTypesArray = [
  'MIN_ANALOG_MA',
  'MAX_ANALOG_MA',
  'MIN_NUMERIC_METER',
  'MAX_NUMERIC_METER',
  'MIN_NUMERIC_DEGREE',
  'MAX_NUMERIC_DEGREE',
];

function putMeasurersParamsInRightPlace(measurer: Measurer, params: any[]) {
  if (measurer.measurementType !== 'WATER_HEIGHT' && measurer.measurementType !== 'TEMPERATURE') return params;
  if (!params || !params.length) return params;
  return params.sort((itemA: any, itemB: any) => {
    return (
      measurerTypesArray.indexOf(itemA.key) -
      measurerTypesArray.indexOf(itemB.key)
    );
  });
}

export default function SpecificParametersForm({
  measurerState,
  setMeasurerState,
  validationErrors,
  validateForm,
}: {
  measurerState: Measurer;
  setMeasurerState: any;
  validationErrors: any;
  validateForm: any;
}): JSX.Element {
  const theme = useTheme();
  const { t } = useTranslation();

  const specificParametersArray = useMemo(() => {
    let parameters = transformObjectToArray(measurerState.specificParameters);
    parameters = putMeasurersParamsInRightPlace(measurerState, parameters);
    return parameters;
  }, [measurerState.specificParameters]);

  const handleChangeSpecificParameters = (e: any) => {
    const { name, value } = e.target;
    let finalValue = value;

    if (!isNull(value)) {
      finalValue = parseFloat(value);
    }
    setMeasurerState((prevState: any) => ({
      ...prevState,
      specificParameters: {
        ...prevState.specificParameters,
        [name]: {
          ...prevState.specificParameters[name],
          fieldValue: finalValue,
        },
      },
    }));
  };

  const getValueForDatePicker = (value: any) => {
    const fieldValue = get(value, 'fieldValue');
    if (fieldValue) return moment(fieldValue);
    return null;
  };

  const handleDatePickerChange = (name: string, newValue: any) => {
    setMeasurerState((prevState: any) => ({
      ...prevState,
      specificParameters: {
        ...prevState.specificParameters,
        [name]: {
          ...prevState.specificParameters[name],
          fieldValue: newValue.format('YYYY-MM-DDTHH:mm:ssZ'),
        },
      },
    }));
  };

  return (
    <>
      {specificParametersArray.length > 0 && (
        <>
          <Divider variant="middle" sx={{ mb: theme.spacing(2) }} />

          <Grid container sx={{ mb: theme.spacing(2) }}>
            {specificParametersArray.map(({ key, value }, index) => (
              <Grid
                item
                xs={6}
                key={index}
                sx={
                  index % 2 === 0
                    ? { pr: theme.spacing(2) }
                    : { pb: theme.spacing(2) }
                }
              >
                {value.fieldType === 'DATE' && (
                  <DateTimePicker
                    label={
                      t(`measurer.specificParameters.${key.toLowerCase()}`) ??
                      ''
                    }
                    slotProps={{
                      textField: { size: 'small', fullWidth: true },
                    }}
                    value={getValueForDatePicker(value)}
                    onChange={(newValue) =>
                      handleDatePickerChange(key, newValue)
                    }
                  />
                )}
                {value.fieldType !== 'DATE' && (
                  <TextFieldCustom
                    label={
                      t(`measurer.specificParameters.${key.toLowerCase()}`) ??
                      ''
                    }
                    name={`${key}`}
                    value={
                      isNil(value.fieldValue) ? '' : value.fieldValue.toString()
                    }
                    onChange={handleChangeSpecificParameters}
                    errors={validationErrors}
                    onBlur={() =>
                      validateForm(
                        {
                          specificParameters: measurerState.specificParameters,
                        },
                        ['specificParameters'],
                      )
                    }
                    type={'number'}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </>
  );
}
